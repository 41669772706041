import React from 'react'
import styled from 'styled-components'

const WorkItemContainer = styled.div``
const WorkItemSubtitle = styled.h5`
    font-size: 1rem;
`

const WorkItem = props => (
    <WorkItemContainer className={props.className}>
        <div className="medium-4 large-4 columns">
            <a className="project-link card-link" href={props.imageLink}>
                <div className="card">
                    <img src={props.image} width="100%" height="auto" />
                </div>
            </a>
        </div>
        <div className="medium-8 large-8 columns">
            <h4 className="project-title">{props.title} - <a className="link-fade" href={props.link}>{props.linkText}</a></h4>
            <WorkItemSubtitle>{props.subtitle}</WorkItemSubtitle>
            <p  className="project-summary">{props.summary}</p>
        </div>
    </WorkItemContainer>
)

export default WorkItem