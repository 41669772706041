import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.css'
import Header from './header'

export default ({ children }) => (
    <StaticQuery
        query={graphql`
            query LayoutQuery {
                site {
                    siteMetadata {
                        title
                        description
                        keywords
                    }
                }
            }
        `}
        render={data => (
            <>
                <Helmet
                    titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                    defaultTitle={data.site.siteMetadata.title}
                    meta={[
                        {
                            name: 'description',
                            content: data.site.siteMetadata.description,
                        },
                        {
                            name: 'keywords',
                            content: data.site.siteMetadata.keywords,
                        },
                    ]}
                />
                <Header siteTitle={data.site.siteMetadata.title} />
                <div>{children}</div>
            </>
        )}
    />
)
