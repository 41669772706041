import React from "react";
// import Link from 'gatsby-link'
import WorkItem from "../components/workItem";
import Layout from "../components/layout";

import "./index-ww.css";
import "./animate.css";
import site_bg from "./imgs/site_bg.jpg";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCoffee,
    faHeart,
    faPhoneSquare
} from "@fortawesome/free-solid-svg-icons";
import {
    faLinkedinIn,
    faTwitter,
    faGithub,
    faAngellist,
    faFacebook,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";

import avatar_ww from "./imgs/avatar_ww.png";
import ww_logo from "../assets/imgs/w.png";
import top_arrow from "./imgs/top_arrow.png";
library.add(
    faLinkedinIn,
    faTwitter,
    faGithub,
    faAngellist,
    faFacebook,
    faInstagram
);

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // loading: false
        };
    }

    componentDidMount() {
        document.body.classList.toggle("page-rendered", true);

        this.image = new Image();
        this.image.src = avatar_ww;
        this.image.onload = this.startIntroAnimation;

        this.image = new Image();
        this.image.src = site_bg;
        this.image.onload = this.loadBackgroundImage;
        this.image.onerror = () => {};

        this.startSlider();
    }

    startIntroAnimation = () => {
        setTimeout(function() {
            // document.querySelector("#header-nav").classList.add("animated", "fadeInDown");
            document
                .querySelector(".avatar-container")
                .classList.add("animated", "fadeInLeft");
            document
                .querySelector(".intro-title")
                .classList.add("animated", "fadeInUp");
            setTimeout(function() {
                document
                    .querySelector(".intro-sub")
                    .classList.add("animated", "fadeInUp");
                setTimeout(function() {
                    document
                        .querySelector(".intro-summary")
                        .classList.add("animated", "fadeInUp");
                    document
                        .querySelector("#scroll-arrow")
                        .classList.add("animated", "fadeInDown");
                }, 1500);
            }, 1500);
        }, 300);
    };

    loadBackgroundImage = () => {
        document.body.classList.toggle("page-bg-loaded", true);
    };

    startSlider() {
        const items = document.querySelectorAll(".slide-item"),
            interval = 7000;

        let current = 0;

        const slider = {
            init: () => {
                items[current].classList.add("active");
            },
            nextSlide: () => {
                // Increment current slide and add active class
                slider.reset();
                if (current === items.length - 1) current = -1; // Check if current slide is last in array
                current++;
                items[current].classList.add("active");
            },
            reset: () => {
                // Remove active classes
                items.forEach(item => item.classList.remove("active"));
            }
        };
        const intervalF = setInterval(slider.nextSlide, interval);
        slider.init();
    }

    render() {
        const staticdata = {
            works: [
                {
                    className: "row project-row",
                    title: "Wataru Watanabe",
                    subtitle: "Personal website and branding",
                    image: require("./imgs/works/thumbnail_logo_skeleton.png"),
                    imageLink:
                        "https://www.behance.net/gallery/37488177/Personal-website-and-branding",
                    link: "https://wataruwatanabe.net",
                    linkText: "wataruwatanabe.net",
                    summary: [
                        "Designed personal website rebuilt with ReactJS.",
                        <br key="br" />,
                        <a
                            key="summary"
                            href="https://www.behance.net/gallery/37488177/Personal-website-and-branding"
                        >
                            View on Behance →
                        </a>
                    ]
                },
                {
                    className: "row project-row",
                    title: "SALE",
                    subtitle: "Industrial kitchen supplier website",
                    image: require("./imgs/works/thumbnail_sale_desktop.png"),
                    imageLink: "http://sale.net.vn",
                    link: "http://sale.net.vn",
                    linkText: "sale.net.vn",
                    summary: [
                        "SALE is a supplier and consultant for industrial kitchens.",
                        <br key="br" />,
                        "The responsive website with Admin area to manage content was built using FuelPHP framework and Twitter Bootstrap."
                    ]
                },
                {
                    className: "row project-row",
                    title: "Eigopedia",
                    subtitle:
                        "A website for teaching and translating English for Japanese",
                    image: require("./imgs/works/thumbnail_eigopedia_desktop.png"),
                    imageLink: "http://www.eigopedia.com",
                    link: "http://www.eigopedia.com",
                    linkText: "eigopedia.com",
                    summary: [
                        "Eigopedia is an English resource site for Japanese who can ask simple questions about various English words and expressions.",
                        <br key="br" />,
                        "The site was built using Wordpress and the theme was customized based on the Simple Bootstrap template."
                    ]
                }
            ]
        };

        return (
            <Layout>
                {/* <Link to="/page-2/">Go to page 2</Link> */}

                <div className="section intro-section">
                    <div className="background" />
                    <div className="site-width intro-wrap">
                        <header id="intro" className="row">
                            <div className="medium-2 columns text-center">
                                <div className="avatar-container">
                                    <img
                                        className="avatar"
                                        src={avatar_ww}
                                        height="80"
                                        width="80"
                                        title="wataru watanabe"
                                    />
                                    <div className="avatar-logo">
                                        <img
                                            src={ww_logo}
                                            height="20"
                                            width="50"
                                            title="wataru watanabe // web developer"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="medium-10 columns">
                                <h1 className="intro-title font-light">
                                    Hello! I'm <strong>Wataru</strong>.
                                </h1>
                                <h2 className="intro-sub font-light">
                                    I’m a nomadic web dev + consultant that can{" "}
                                    <strong>
                                        help you create wonderful things
                                    </strong>{" "}
                                    for your{" "}
                                    <strong>
                                        web, system and business needs
                                    </strong>
                                    .
                                </h2>
                                <p className="intro-summary">
                                    I believe that having passion and clear core
                                    values are crucial to any project, big or
                                    small.
                                    <br />I strive to make great contributions
                                    to your projects and people's lives.
                                </p>
                            </div>
                        </header>
                    </div>
                    <div id="scroll-arrow">
                        <img src={top_arrow} height="16" width="32" />
                    </div>
                </div>
                <div className="section">
                    <div id="about" className="site-width">
                        <div className="row">
                            <div className="large-4 columns">
                                <h2 className="section-title font-light">
                                    about
                                </h2>
                            </div>
                            <div className="large-8 columns about-me-txt">
                                <p>
                                    From early in my childhood, I always loved
                                    to envision, create, as well as fix and
                                    tinker with things. Solving problems,
                                    improving or making something better is
                                    something I always enjoy.
                                </p>
                                <p>
                                    After getting my degree in Industrial Design
                                    (BID) in university with some professional
                                    work placements, I ventured out of my
                                    hometown of Ottawa, Canada and went to Japan
                                    and South East Asia for travel and a bit of
                                    soul searching. During that time I was
                                    involved with various projects and adapted
                                    my skills to become a web developer. Some
                                    projects I was involved with were:
                                </p>
                                <ul>
                                    <li>
                                        Design and coding of a desktop and
                                        responsive mobile websites.
                                    </li>
                                    <li>
                                        E-commerce sites, portal sites,
                                        reservation systems and APIs.
                                    </li>
                                    <li>
                                        Wordpress sites with custom designs and
                                        logic.
                                    </li>
                                    <li>
                                        Integrating various third-party
                                        APIs/Services.
                                    </li>
                                    <li>Code review and code optimization</li>
                                </ul>
                                <p>
                                    I work remotely mainly because it fits my
                                    lifestyle, but it also allows me to get
                                    various experiences and challenges from
                                    various clients and projects around the
                                    world. I'm also constantly learning other
                                    software and programming languages to
                                    experiment and to further broaden my skills,
                                    as well as connecting with others to build a
                                    team and network of professionals to better
                                    serve your needs.
                                </p>
                                <p>
                                    When I'm off work and studying I love
                                    spending time with my family, exploring (the
                                    world or the neighbourhood), taking photos
                                    or just chillin' in a café.
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="large-4 columns">
                                <h3 className="section-sub-title">
                                    tools &amp; skillset
                                </h3>
                            </div>
                            <div className="large-8 columns about-me-txt">
                                <div className="row">
                                    <div className="medium-4 columns">
                                        <h5>Languages</h5>
                                        <ul>
                                            <li>Javascript</li>
                                            <li>HTML5</li>
                                            <li>CSS3</li>
                                            <li>MySQL</li>
                                            <li>PHP</li>
                                            <li>English, Japanese</li>
                                        </ul>
                                    </div>

                                    <div className="medium-4 columns">
                                        <h5>Tools</h5>
                                        <ul>
                                            <li>Visual Studio Code</li>
                                            <li>Sketch</li>
                                            <li>Adobe CS/CC</li>
                                            <li>Google</li>
                                            <li>MacBook Pro</li>
                                        </ul>
                                    </div>

                                    <div className="medium-4 columns">
                                        <h5>Libraries/Frameworks</h5>
                                        <ul>
                                            <li>FuelPHP</li>
                                            <li>ReactJS//Expo</li>
                                            <li>ExpressJS</li>
                                            <li>JQuery/AJAX</li>
                                            <li>Bootstrap</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section darken-bg">
                    <div id="process" className="site-width">
                        <div className="row">
                            <div className="large-4 columns">
                                <h2 className="section-title font-light">
                                    process
                                </h2>
                            </div>
                            <div className="large-8 columns about-me-txt">
                                <h4>
                                    <span className="process-number">01</span>{" "}
                                    Consult + Research
                                </h4>
                                <p>
                                    We begin the project by understanding the
                                    goals, about you and your users or
                                    customers.
                                </p>
                                <br />
                                <h4>
                                    <span className="process-number">02</span>{" "}
                                    Design + Spec
                                </h4>
                                <p>
                                    We start to design: brainstorm, iterate and
                                    refine ideas into more concrete concepts
                                    and/or working prototypes.
                                </p>
                                <br />
                                <h4>
                                    <span className="process-number">03</span>{" "}
                                    Develop + Optimize
                                </h4>
                                <p>
                                    We develop the solution and optimize as much
                                    as we can to provide the best performance
                                    and experience possible.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="site-width">
                        <div id="works" className="row">
                            <div className="large-12 columns">
                                <h2 className="section-title font-light">
                                    works
                                </h2>
                            </div>
                            <div className="large-12 columns">
                                {staticdata.works.map(workItem => (
                                    <WorkItem
                                        key={workItem.title}
                                        className="row project-row"
                                        title={workItem.title}
                                        subtitle={workItem.subtitle}
                                        image={workItem.image}
                                        imageLink={workItem.imageLink}
                                        link={workItem.link}
                                        linkText={workItem.linkText}
                                        summary={workItem.summary}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section lighten-bg">
                    <div className="site-width">
                        <div id="testimonials" className="row">
                            <div className="large-12 columns">
                                <div className="testimonials slideshow">
                                    <div className="testimonial slide-item">
                                        <div className="large-12 columns">
                                            <q>
                                                Wataru is a pleasure to work
                                                with and has improved our
                                                website's design considerably.
                                                He has allowed us to concentrate
                                                on our core business more and
                                                leave him to optimize our online
                                                presence.
                                            </q>
                                            <p className="quoteby">
                                                Nicholas Goold, CEO <br />
                                                <a href="https://www.turntrading.com">
                                                    turntrading.com
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="testimonial slide-item">
                                        <div className="large-12 columns">
                                            <q>
                                                Wataru was the perfect choice
                                                for creating a website for my
                                                industrial design company, as he
                                                has the same background as I do.
                                                He understood completely what I
                                                needed and created a website
                                                which gained many praises from
                                                my partners and customers. I'm
                                                really happy to have worked with
                                                Wataru!
                                            </q>
                                            <p className="quoteby">
                                                Calvin H, Legion Productions{" "}
                                                <br />
                                                <a href="https://legion.sg">
                                                    legion.sg
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="site-width">
                        <div id="contact" className="row">
                            <div className="medium-6 large-4 columns">
                                <h2 className="section-title font-light">
                                    connect with me
                                </h2>
                                <ul className="social-icons">
                                    <li>
                                        <a
                                            href="https://www.linkedin.com/in/wataruwatanabe"
                                            className="fa-icon-btn icon-fade"
                                        >
                                            <FontAwesomeIcon
                                                icon={["fab", "linkedin-in"]}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://twitter.com/watarunabe"
                                            className="fa-icon-btn icon-fade"
                                        >
                                            <FontAwesomeIcon
                                                icon={["fab", "twitter"]}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://github.com/wataru22"
                                            className="fa-icon-btn icon-fade"
                                        >
                                            <FontAwesomeIcon
                                                icon={["fab", "github"]}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://angel.co/wataru-watanabe"
                                            className="fa-icon-btn icon-fade"
                                        >
                                            <FontAwesomeIcon
                                                icon={["fab", "angellist"]}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/wataruwatanabe.dev/"
                                            className="fa-icon-btn icon-fade"
                                        >
                                            <FontAwesomeIcon
                                                icon={["fab", "instagram"]}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/wataruwatanabe.net/"
                                            className="fa-icon-btn icon-fade"
                                        >
                                            <FontAwesomeIcon
                                                icon={["fab", "facebook"]}
                                            />
                                        </a>
                                    </li>
                                </ul>
                                <p>
                                    Use the blue button in the lower right to
                                    let me know directly if you have any
                                    questions, comments or anything you would
                                    like to discuss with me.
                                </p>
                                <p>
                                    I will try to reply ASAP, usually within a
                                    day or two.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <a id="btn-scroll-top" className="btn-scroll-top scroll" data-href="#top">
                    <img src="https://wataruwatanabe.net/assets/img/top_arrow.png" height="16" width="32"/>
                </a> */}
                <footer className="">
                    <div className="site-width">
                        <div id="footer" className="row">
                            <div className="large-12 columns">
                                <small className="ftrCopyright">
                                    Copyright &copy; wataru watanabe
                                </small>
                                <p>
                                    <small>
                                        Made with{" "}
                                        <FontAwesomeIcon icon={faHeart} /> +{" "}
                                        <FontAwesomeIcon icon={faCoffee} />
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </Layout>
        );
    }
}

export default IndexPage;
